import { useStaticQuery, graphql } from 'gatsby'
import dedupe from 'dedupe'

export const useBlogProducts = () => {
  const { allArticleProduct } = useStaticQuery(productsQuery)
  return allArticleProduct.nodes
    ? dedupe(allArticleProduct.nodes, node => node.slug)
    : null
}

const productsQuery = graphql`
  query allArticleProductQuery {
    allArticleProduct {
      nodes {
        ...ArticleProduct
      }
    }
  }
`
