import React from 'react'
import { Link } from 'gatsby'
import { Flex, Button, Heading, Text } from 'theme-ui'
import { FaRegHandshake } from 'react-icons/fa'

const styles = {
  wrapper: {
    alignItems: `center`,
    flexDirection: `column`,
    bg: `omegaLighter`,
    borderRadius: `lg`,
    size: `full`,
    p: 4,
  },
  heading: {
    color: `omegaDark`,
    wordSpacing: 500, //force line breaks
    svg: {
      color: `beta`,
      size: `icon.lg`,
      display: `block`,
      mb: 3,
    },
  },
  subheading: {
    color: `omegaDark`,
    fontWeight: `normal`,
  },
  list: {
    color: `omegaDark`,
    listStyle: `none`,
    m: 0,
    p: 0,
    li: {
      p: 0,
      my: 2,
    },
    'li:before': {
      content: `""`,
      display: `inline-block`,
      width: `icon.xs`,
      bg: `success`,
      borderRadius: `full`,
      size: `7px`,
      mr: `7px`,
    },
  },
  button: {
    display: `block`,
    mt: `auto`,
  },
  link: {
    color: '#4CAF50',
    textDecoration: 'none',
  },
}

const BannerVertical = () => (
  <Flex sx={styles.wrapper}>
    <Heading variant='h2' sx={styles.heading}>
      <FaRegHandshake />
      Enterprise Support
    </Heading>
    <Heading variant='h4' sx={styles.subheading}>
      We made the serious commitment of providing the best-in-class support for our projects. If you have any doubts, do not hesitate to contact us!
    </Heading>
    <Text as='ul' variant='small' sx={styles.list}>
      When reaching out to us:
      <li>Take a look at our <a style={styles.link} href="https://forum.honeyside.it/" target="_blank">Forum</a>. Maybe your question has already been answered!</li>
      <li>Explain the issue that you are experiencing with as much detail as you can.</li>
      <li>Attach your license or subscription number.</li>
    </Text>
    <Button
      variant='secondary'
      as={Link}
      to='/support'
      sx={styles.button}
      aria-label='Get Support'
    >
      Get Support
    </Button>
  </Flex>
)

export default BannerVertical
